import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { menu } from 'app/menu/menu';
import { locale as menuEnglish } from 'app/menu/i18n/en';
import { locale as menuFrench } from 'app/menu/i18n/fr';
import { locale as menuGerman } from 'app/menu/i18n/de';
import { locale as menuPortuguese } from 'app/menu/i18n/pt';
import moment from "moment-timezone";
import { CONNREFUSED } from 'dns';
import { NavigationEnd, Router, Event as NavigationEvent, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { CommonObservableService } from './services/common-observable.service';
import { AuthenticationService } from './auth/service';
import { HttpService } from './services/http.service';
import { LearningHttpService } from './services/learning-http.service';
export let browserRefresh = false;
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BnNgIdleService } from 'bn-ng-idle'; // for auto logout 
import { LocalStorageService } from './services/localStorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'en'; // Set application default language i.e fr
  subscription: Subscription;



  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
  currentRoute: any; x: any;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _router: Router,
    private cos: CommonObservableService,
    private _authenticationService: AuthenticationService,
    private httpService: HttpService,
    private learningHttpService: LearningHttpService,
    private ngxLoader: NgxUiLoaderService,
    private bnIdle: BnNgIdleService,
    private localStorageService: LocalStorageService,
    private ref:ChangeDetectorRef
  ) {
      if ( window.location.href === 'http://localhost:4003' || window.location.href === 'http://localhost:4003/' || window.location.href === 'https://admin.enrichedacademy.com/' || window.location.href === 'https://admin.enrichedacademy.com' || window.location.href === 'https://staging.admin.enrichedacademy.com' || window.location.href === 'https://staging.admin.enrichedacademy.com/') {
        this._router.navigate(['/admin/dashboard']);
      }    


    this.currentRoute
      = this._router.events
        .subscribe(
          (event: NavigationEvent) => {
            if (event instanceof NavigationStart) {

              this.x = event.url;

              let initialValue = ""; let selctedUserId = 'RETURN';
              let dashURL = "/admin/dashboard";
              if (this.x.length == 1) { this.x = dashURL }
              if (this.x.toString() != "/admin/dashboard") {
                
                this.cos.getGlobalSearch(selctedUserId);
                this.cos.updateUserFormState('add');
                

              }
              else {
                let selctedUserId = 'RETURN_ON_DASHBOARD'
              

                this.cos.getGlobalSearch(selctedUserId);
              }

            }
          });

    // Get the application main menu
    this.menu = menu;

    // Register the menu to the menu service
    this._coreMenuService.register('main', this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu('main');

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'fr', 'de', 'pt']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('en');

    // Set the translations for the menu
    this._coreTranslationService.translate(menuEnglish, menuFrench, menuGerman, menuPortuguese);

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.subscription = _router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !_router.navigated;
        this.ngxLoader.start();
      } else if (event instanceof NavigationEnd || event instanceof NavigationError) {
        // add this condtion manage in forgotpassword  page routing (gokul)
        const notAllowedRoutes = ['/admin/forgotpassword', '/admin/resetpassword'];   
        if (!(event instanceof NavigationEnd && notAllowedRoutes.some(route => event.url.includes(route)))) {
          this.checkIsAccountActive(); // check if login user exists in database
        }
        this.ngxLoader.stop();
      }
    });



  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  // auto logout if user not found  or inactive 
  async checkIsAccountActive() {
    let userData = this._authenticationService.getLogedInData();
    if (!userData) {
      if (userData == undefined) this._authenticationService.logoutForcefully();
    }else{
      this.httpService.post('/checkIfUserExists', { user_id: userData?.id, role_id: userData?.role_id }).subscribe((ResUserData: any) => {
        if (ResUserData['status']) {
          if (ResUserData?.data?.length == 0) this._authenticationService.logoutForcefully();
        }
      });
    }
  }

  autoLogout() {
    this.bnIdle.startWatching(60 * 20).subscribe((res) => { // 1=1 second => 5*60=300 seconds
      if (res) {
        if (this._authenticationService.isLogin() == true) {
          this.httpService.logoutForcefullyIdeal();
        }
      }
    })
  }
  /**
   * On init
   */
  ngOnInit(): void {

    this.localStorageService.getStorageEvent().subscribe((cleared) => {
      if (cleared) {
        if (this._authenticationService.isLogin() == false) {

        } else {

          location.reload();
        }
        // Perform actions when localStorage is cleared in all tabs
      }
    });


    this.autoLogout();

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        //returns installed service workers
        if (registrations.length) {
          console.log('registrations', registrations)
          for (let registration of registrations) {
            registration.unregister();
          }
        }
      });
    }
    // moment.tz.setDefault("America/New_York")
    moment.tz.setDefault("Asia/Kolkata")

    // Init wave effect (Ripple effect)
    Waves.init();

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;

      // Set application default language.

      // Change application language? Read the ngxTranslate Fix

      // ? Use app-config.ts file to set default language
      const appLanguage = this.coreConfig.app.appLanguage || 'en';
      this._translateService.use(appLanguage);

      // ? OR
      // ? User the current browser lang if available, if undefined use 'en'
      // const browserLang = this._translateService.getBrowserLang();
      // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

      /**
       * ! Fix : ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      /**
       *
       * Using different language than the default ('en') one i.e French?
       * In this case, you may find the issue where application is not properly translated when your app is initialized.
       *
       * It's due to ngxTranslate module and below is a fix for that.
       * Eventually we will move to the multi language implementation over to the Angular's core language service.
       *
       **/

      // Set the default language to 'en' and then back to 'fr'.

      setTimeout(() => {
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang(appLanguage);
      });

      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

    // Set the application page title
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.currentRoute.unsubscribe();
    this.subscription.unsubscribe();



  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }
/* To remove the warning popup from ckEditor
why we are not using the official way because the 
notification plugin is required so the ckEditor 
do not allow us ro remove that plugin
*/
  ngAfterViewChecked(){
    let elements: any = document.querySelectorAll('.cke_notification_message');
    if(elements.length){
    elements.forEach((element: any) => {
       if (element.textContent.includes('is not secure')) {
         element.parentElement.remove();
         this.ref.detectChanges()  
        }
      });
    }
  }
}
