import { CoreMenu } from '@core/types';
import { environment } from 'environments/environment';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [


  /********************New Menu Start********************/

  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'item',

    img: `${environment.assetsUrl}admin/admin-navbar-icons/home.svg`,
    url: 'admin/dashboard',


  },
  {
    id: 'user',
    title: 'Manage Account',
    translate: 'Manage Account',
    type: 'collapsible',
    // icon: 'user',
    img: `${environment.assetsUrl}admin/admin-navbar-icons/users.svg`,
    children: [


      {
        id: 'Industry',
        title: 'Industries',
        translate: 'Industries',
        type: 'item',
        icon: 'circle',
        url: 'user-management/manage-industry'
      },



      {
        id: 'company',
        title: 'Companies',
        translate: 'Companies',
        type: 'item',
        icon: 'circle',
        url: 'user-management/manage-companies'
      },
      {
        id: 'Company Admin',
        title: 'Company Admins',
        translate: 'Company Admins',
        type: 'item',
        icon: 'circle',
        url: 'user-management/manage-company-admins'
      },




      {
        id: 'Clients',
        title: 'Clients',
        translate: 'Clients',
        type: 'item',
        icon: 'circle',
        url: 'user-management/manage-clients'
      },
      {
        id: 'Client Admin',
        title: 'Client Admins',
        translate: 'Client Admins',
        type: 'item',
        icon: 'circle',
        url: 'user-management/manage-client-admins'
      },
      {
        id: 'Teachers',
        title: 'Teachers',
        translate: 'Teachers',
        type: 'item',
        icon: 'circle',
        url: '/user-management/manage-teachers'
      },

      {
        id: 'Review Teachers',
        title: 'Review Teachers',
        translate: 'Review Teachers',
        type: 'item',
        icon: 'circle',
        url: 'user-management/manage-client-temporary'
      },






      {
        id: 'company',
        title: 'Users',
        translate: 'Users',
        type: 'item',
        icon: 'circle',
        url: 'user-management/manage-users'
      },




    ]

  },


  {
    id: 'coursemanagement',
    title: 'User Resources',
    translate: 'User Resources',
    type: 'collapsible',
    
    icon: 'book-open',
   
    children: [
      {
        id: 'Courses',
        title: 'Courses',
        translate: 'Courses',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-courses'
      },
      {
        id: 'Modules',
        title: 'Modules',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-modules'
      },
      {
        id: 'Topics',
        title: 'Topics',
        translate: 'Topics',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-topics'
      },
      {
        // If role is not assigned will be display to all
        id: 'Videos',
        title: 'Videos',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-videos'
      },
      {
        // If role is not assigned will be display to all
        id: 'Quizzes',
        title: 'Quizzes',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-questions'
      },
      {
        id: 'Books',
        title: 'Books',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-books'
      },
      {
        id: 'Essential Tiles',
        title: 'Essential Tiles',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-tiles'
      },
      {
        id: 'Expert Interviews',
        title: 'Expert Interviews',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-experts'
      },
      {
        id: 'Event & Webinars',
        title: 'Events Webinar Master',
        translate: 'Events & Webinars',
        type: 'item',
        icon: 'circle',
        url: 'media-management/events-and-webinars'
      },
      {
        id: 'Recorded Webinars',
        title: 'Recorded Webinars',
        translate: 'Recorded Webinars',
        type: 'item',
        icon: 'circle',
        url: 'media-management/recorded-webinars'
      },
      {
        id: 'Downloadable Tools',
        title: 'Downloadable Tools',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-downloadable-tools'
      },
      {
        id: 'Age Groups',
        title: 'Age Groups',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-age-groups'
      },


      {
        id: 'Banners',
        title: 'Banners',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-footer-banners'
      },
     




      {
        // If role is not assigned will be display to all
        id: 'Polls',
        title: 'Polls',
        translate: 'MENU.DASHBOARD.ECOMMERCE',
        type: 'item',
        icon: 'circle',
        url: 'course-management/manage-polls'
      },

     
    ]
  },


 
  {
    id: 'CMS',
    title: 'CMS',
    translate: 'CMS',
    type: 'collapsible',
    
    img: `${environment.assetsUrl}admin/admin-navbar-icons/cmc.svg`,
    children: [
      {
        id: 'Blogs',
        title: 'Blogs',
        translate: 'Blogs',
        type: 'item',
        icon: 'circle',
        url: 'post-management/manage-posts'
      },
      {
        id: 'Comments',
        title: 'Blog Comments',
        translate: 'Blog Comments',
        type: 'item',
        icon: 'circle',
        url: 'post-management/post-comments'
      },
      {
        id: 'New-Features',
        title: 'New Features',
        translate: 'New Features',
        type: 'item',
        icon: 'circle',
        url: 'post-management/new-features'
      },
     
    ]
  },



  {
    id: 'Testimonials',
    title: 'Testimonials',
    translate: 'Testimonials',
    type: 'item',
    img: `${environment.assetsUrl}admin/admin-navbar-icons/testimonials.svg`,
    url: 'testimonial-management/manage-testimonials'
  },

  {
    id: 'scholarship-management',
    title: 'Scholarships',
    translate: 'Scholarships',
    type: 'collapsible',
    img: `${environment.assetsUrl}admin/admin-navbar-icons/scholarships.svg`,
    children: [
      {
        id: 'alberta-scholarship-master',
        title: 'Alberta',
        translate: 'Alberta',
        type: 'item',
        icon: 'circle',
        url: 'scholarship-management/alberta-scholarship-master'
      },

      {
        id: 'post-secondary-master',
        title: 'Post Secondary',
        translate: 'Post Secondary',
        type: 'item',
        icon: 'circle',
        url: 'scholarship-management/post-secondary-master'
      },

      {
        id: 'national-scholarship-master',
        title: 'National',
        translate: 'National',
        type: 'item',
        icon: 'circle',
        url: 'scholarship-management/national-scholarship-master'
      },

      {
        id: 'manitoba-educator',
        title: 'Manitoba',
        translate: 'Manitoba',
        type: 'item',
        icon: 'circle',
        url: 'scholarship-management/manitoba-scholarship-educator'
      },

    ]
  },
  {
    id: 'logs',
    title: 'Logs',
    translate: 'Logs',
    type: 'collapsible',
    img: `${environment.assetsUrl}admin/admin-navbar-icons/logs.svg`,
    children: [
      {
        id: 'emaillogs',
        title: 'Email Logs',
        translate: 'Email Logs',
        type: 'item',
        icon: 'circle',
        url: 'admin/email-logs'

      },
    ]

  },

  {
    id: 'Media Master',
    title: 'Media Master',
    translate: 'Media',
    type: 'item',
    icon: 'layers',
    url: 'media-management/manage-media'
  },
  {
    id: 'siteadministrator',
    title: 'Site Administrator',
    translate: 'Site Administrator',
    type: 'collapsible',
    img: `${environment.assetsUrl}admin/admin-navbar-icons/site-dministrator.svg`,
    children: [
      {
        id: 'staff Master',
        title: 'Staff',
        translate: 'Staff',
        type: 'item',
        icon: 'circle',
        url: 'staff-management/manage-staff'
      },
      {
        id: 'Role',
        title: 'Role',
        translate: 'Role',
        type: 'item',
        icon: 'circle',
        url: 'staff-management/manage-roles'
      },
    ]
  },

  {
    id: 'Developer Tools',
    title: 'Developer Tools',
    translate: 'Developer Tools',
    type: 'collapsible',
    img: `${environment.assetsUrl}admin/admin-navbar-icons/user-resoures.svg`,
    children: [
      {
        id: 'emailtemplate',
        title: 'Email Template',
        translate: 'Email Template',
        type: 'item',
        icon: 'circle',
        url: 'admin/email-template'
      },
    ]
  },
  {
    id: 'globalSettingManagement',
    title: 'Global settings',
    translate: 'Global settings',
    type: 'collapsible',
    icon: 'book-open',
    children :[ {
      id: 'ROI Report Pricing',
      title: 'ROI Pricing',
      translate: 'MENU.DASHBOARD.ECOMMERCE',
      type: 'item',
      icon: 'circle',
      url: 'admin/pricing'
    },
    {
      id: 'ROI Report Content',
      title: 'Manage ROI',
      translate: 'MENU.DASHBOARD.ECOMMERCE',
      type: 'item',
      icon: 'circle',
      url: 'admin/manage-roi'
    },
  ]

    
  },
  {
    id: 'coursemanagement',
    title: 'Reports',
    translate: 'Reports',
    type: 'collapsible',
    icon: 'book-open',
    children :[ {
      id: 'AB Teacher Report',
      title: 'AB Teacher Report',
      translate: 'MENU.DASHBOARD.ECOMMERCE',
      type: 'item',
      icon: 'circle',
      url: 'course-management/manage-report'
    },
    {
      id: 'ROI Report',
      title: 'ROI Report',
      translate: 'MENU.DASHBOARD.ECOMMERCE',
      type: 'item',
      icon: 'circle',
      url: 'admin/roi-report'
    },
    {
      id: 'Strategy Session Report',
      title: 'Strategy Session Report',
      translate: 'MENU.DASHBOARD.ECOMMERCE',
      type: 'item',
      icon: 'circle',
      url: 'course-management/strategy-session-report'
    },
    {
      id: 'Will Report',
      title: 'Will Report',
      translate: 'MENU.DASHBOARD.ECOMMERCE',
      type: 'item',
      icon: 'circle',
      url: 'admin/will-report'
    }
  ]

    
  },


]