export const VALIDATION_MESSAGE = {
  AGE_GROUP_REQUIRED: "Age Group is required!",
  ANSWER_HINT_REQUIRED: "Answer Hint is required!",
  ANSWER_REQUIRED: "Answer is required!",
  AUTHOR_REQUIRED: "Author is required!",
  CORRECT_ANSWER_REQUIRED: "Correct Answer is required!",
  BACKGROUND_IMAGE_REQUIRED: "Background Image is required!",
  BANNER_TYPE_REQUIRED: "Banner Type is required!",
  BANNER_SIZE_REQUIRED: "Banner Size is required!",
  BOAST_COACHING_REQUIRED: "Boast / Coaching is required!",
  BOOK_IMAGE_REQUIRED: "Book Image is required!",
  BOOK_LINK_NOT_VALID: "Book Link is not valid!",
  BOOK_LINK_REQUIRED: "Book Link is required!",
  BOOK_NAME_REQUIRED: "Book Name is required!",
  BULK_UPLOAD_REQUIRED: "column(s) not in the file. Correct them add re-upload the file again. If you need any assistance, please contact us at support@enrichedacademy.com.",
  CATEGORY_REQUIRED: "Category is required!",
  CITY_REQUIRED: "City is required!",
  CLIENT_NAME_REQUIRED: "Client Name is required!",
  TEACHER_NAME_REQUIRED: "Teacher Name is required!",
  CLIENT_REQUIRED: "Client is required!",
  COMPANY_REQUIRED: "Company is required!",
  COUNTRY_REQUIRED: "Country is required!",
  COURSE_REQUIRED: "Course is required!",
  COURSE_TITLE_REQUIRED: "Course Title is required!",
  COURSE_TYPE_REQUIRED: "Course Type is required!",
  CONFIRM_PASSWORD_REQUIRED: "Confirm Password is required!",
  CURRENT_PASSWORD_REQUIRED: "Current Password is required!",
  NEW_PASSWORD_REQUIRED: "New Password is required!",
  DEFAULT_COURSE_REQUIRED: "Default Course is required!",
  DESCRIPTION_AFTER_REQUIRED: "Description After is required!",
  DESCRIPTION_BEFORE_REQUIRED: "Description Before is required!",
  DESCRIPTION_REQUIRED: "Description is required!",
  SHORT_DESCRIPTION_REQUIRED: "Short description is required!",
  LONG_DESCRIPTION_REQUIRED: "Long description is required!",
  DESIGNATION_REQUIRED: "Designation is required!",
  DISPLAY_NAME_REQUIRED: "Display Name is required!",
  DOCUMENT_REQUIRED: "Document is required!",
  EMAIL_ALREADY: "Email already exist!",
 
  EMAIL_NOT_VALID: "Email is not valid, please enter a valid email!",
  EMAIL_REQUIRED: "Email is required!",
  EMAIL_SAME: "Email and Alternate Email are same!",
  EVENT_TYPE_REQUIRED: "Event Type is required!",
  WEBINAR_TYPE_REQUIRED: "Webinar Type is required!",
  ACCOUNT_TYPE_REQUIRED: "Account Type is required!",
  EVENT_NAME_REQUIRED: "Event Name is required!",
  EVENT_START_DATE_REQUIRED: "Event Start Date is required!",
  EVENT_END_DATE_REQUIRED: "Event End Date is required!",
  EVENT_TIME_ZONE_REQUIRED: "Event Time Zone is required!",
  EVENT_LINK_REQUIRED: "Event Link is required!",
  EVENT_LINK_NOT_VALID: "Event Link is not valid!",
  EVENT_IMAGE_REQUIRED: "Event Image is required!",
  EXPERT_NAME_REQUIRED: "Expert Name is required!",
  EXPERT_REQUIRED: "Expert Name is required!",
  EXTERNAL_LINK_NOT_VALID: "External link is not valid!",
  FEATURED_IMAGE_REQUIRED: 'Featured image is required!',
  FILE_NAME_REQUIRED: "Title is required!",
  FILE_REQUIRED: "File is required!",
  FILE_TYPE_REQUIRED: "File Category is required!",
  FIRST_NAME_REQUIRED: "First Name is required!",
  FULL_NAME_REQUIRED: "Full Name is required!",
  HEADING_REQUIRED: "Heading is required!",
  ICON_REQUIRED: "Icon is required!",
  IMAGE_REQUIRED: "Image is required!",
  INVALID_EXTENSION: "Invalid extension!",
  INVALID_SIZE: "Please ensure your file size is less than {{VALUE}} MB!",
  INVALID_NUMBER: 'Invalid Phone/Mobile number!',
  LAST_NAME_REQUIRED: "Last Name is required!",
  LINK_NOT_VALID: "Link is not valid!",
  MAX_UPLOAD: "Maximum upload file size: {{VALUE}} MB.",
  META_DESCRIPTION_REQUIRED: 'Meta-description is required!',
  NAME_REQUIRED: 'Name is required!',
  ONLINE_LINK_NOT_VALID: "Online Link is not valid!",
  OPTION_REQUIRED: "Option {{VALUE}} is required!",
  PASSWORD_MEDIUM: "Medium",
  PASSWORD_REQUIRED: "Password is required!",
  PASSWORD_STRONG: "Strong",
  PASSWORD_WEAK: "Weak (To help protect you,use a password that is at least 8 characters.Please include at least one number,special character and both upper & lower letters and white space are not allowed.)",
  PASSWORD_NOT_MATCH: "New password and confirm password does not match!",
  PHONE_NUMBER_REQUIRED: "Phone number is required!",
  PHONE_NUMBER_NOT_VALID: "Phone number not valid!",
  PHONE_NUMBER_MUST_BE: "Phone number must be 10 digit!",
  PHOTO_REQUIRED: "Photo is required!",
  PLEASE_MARK: "Please mark fill in the blanks!.",
  QUESTION_BEHAVIOUR_REQUIRED: "Question Behaviour is required!",
  QUESTION_CATEGORY_REQUIRED: "Question Category is required!",
  QUESTION_NATURE_REQUIRED: "Question Nature is required!",
  QUESTION_REQUIRED: "Question is required!",
  QUESTION_TYPE_REQUIRED: "Question Type is required!",
  RIGHT_ANSWER_REQUIRED: "Right Answer is required!",
  ROLE_REQUIRED: "Role is required!",
  SEND_WELCOME_MAIL_REQUIRED: 'Send Welcome Mail is required!',
  SELECT_IMAGE: 'Please select image!',
  SOURCE_TYPE_REQUIRED: "Source Type is required!",
  STATE_REQUIRED: "State is required!",
  CLIENT_TYPE_REQUIRED:"Client type is required!",
  CLIENT_STATUS_REQUIRED:"Client status is required!",
  STATUS_REQUIRED: "Status is required!",
  SUBJECT_REQUIRED: "Subject is required!",
  TAGS_REQUIRED: "Tags is required!",
  TESTIMONIAL_TEXT_REQUIRED: "Testimonial Text is required!",
  TITLE_AFTER_REQUIRED: "Title After is required!",
  TITLE_BEFORE_REQUIRED: "Title Before is required!",
  TITLE_REQUIRED: "Title is required!",
  TOPIC_NAME_REQUIRED: "Topic Name is required!",
  TOPIC_KEY_REQUIRED: "Topic Key is required!",
  VIDEO_KEY_REQUIRED: "Video Key is required!",
  TEMPLATE_BODY_REQUIRED: "Template Body is required!",
  USERNAME_REQUIRED: "Username is required!",
  USERID_REQUIRED: "User Id is required!",
  CLASS_CODE_REQUIRED:"Access Code is required!",
  DEFAULT_CLASS_CODE_REQUIRED:"Default Class Code is required!",
  DEFAULT_ACCESS_CODE_REQUIRED:"Default Access Code is required!",
  USERNAME_ALREADY: "Username already exists, please try with a new username!",
  VARIABLE_REQUIRED: "Variable id is required!",
  VIDEO_ID_REQUIRED: "Video Id is required!",
  VIDEO_NAME_REQUIRED: "Video Name is required!",
  VIDEO_REQUIRED: "Video is required!",
  VIDEO_SOURCE_TYPE_REQUIRED: 'Source Type is required!',
  VIDEO_SPACE_NOT_ALLOWED_REQUIRED: 'Space is not allowed!',
  VIDEO_SPECIFIC_REQUIRED: "Video Specific is required!",
  VIDEO_TYPE_REQUIRED: "Video Type is required!",
  WEB_LINK_NOT_VALID: "Web Link is not valid!",
  WEBINAR_END_DATE_REQUIRED: "Webinar End Date is required!",
  WEBINAR_NAME_REQUIRED: "Webinar Name is required!",
  WEBINAR_START_DATE_REQUIRED: "Webinar Start Date is required!",
  WEBINAR_IMAGE_REQUIRED: "Webinar Image is required!",
  WEBINAR_TIME_ZONE_REQUIRED: "Webinar Time Zone is required!",
  WEBINAR_LINK_REQUIRED: "Webinar Link is required!",
  WEBINAR_LINK_NOT_VALID: "Webinar Link is not valid!",
  COLOR_MSG: "Background, Text, and Tertiary colors are required!",
  COMPANY_NAME_REQUIRED: "Company Name is required!",
  COMPANY_NAME_ALREADY: "Company Name already exists, please use another!",
  INDUSTRY_REQUIRED: "Industry Name is required!",
  DOMAIN_ALREADY: "Domain already used",
  DOMAIN_NOT_VALID: "Domain not valid",
  CSV_REQUIRED: "Csv file is required!",
  ZIP_REQUIRED: "Zip file is required!",
  CERTIFICATE_NAME: "Certificate of achievement is required!",
  COURSE_TIME: "Course time is required!",
  COURSE_TIME_SHOULD_BE: "Time should be in hh:mm",
  PASSING_PERCENTAGE: "Passing percentage is required!",
  INVALID_SCORE: "Invalid passing percentage!",
  CLIENT_EMAIL_DOMAIN: "Client E-mail Domain is required!",
  CHILD_PROFILES: "No. of Child Profiles is required!",
  FACEBOOK_LINK: "Facebook link is not valid",
  LINKDIN_LINK: "Linkedin link is not valid",
  TWITTER_LINK: "Twitter link is not valid",
  INSTAGRAM_LINK: "Instagram link is not valid",
  CONTACT_BANNER_REQUIRED: 'Contact banner is required!',
  EXPERT_INTERVIEW_REQUIRED: 'Expert interview is required!',
  DROPDOWN_CALCULATORS_REQUIRED: 'Calculator is required!',
  DROPDOWN_TOOL_REQUIRED: 'Tool is required!',
  SPECIFIC_WEBINAR_REQUIRED: 'Specific webinar is required!',
  POLL_NAME_REQUIRED: "Name is required!",
  POLL_START_DATE_REQUIRED: "Start Date is required!",
  POLL_END_DATE_REQUIRED: "End Date is required!",
  POLL_COURSE_REQUIRED: "Course is required!",
  POLL_STATUS_REQUIRED: "Status is required!",
  INDUSTRY_NAME_ALREADY: "Industy Name already exists, please use another! ",
  FINANCIAL_ASSESMENT_REQUIRED: "Financial Assesment Calls is required!",
  URL_REQUIRED: "Url is required!",
  MODULE_REQUIRED: "Module Name is required!",
  NO_QUESTION_REQUIRED: "Number of Question is required!",
  MODULE_KEY_REQUIRED:"Module key is required",
  SPACE_NOT_ALLOWED: "Space not allowed!",
  MAX_CHARACTER_ALLOW: "Maximum {{value}} characters are allowed!",
  MAX_DIGIT_ALLOW: "Maximum {{value}} digits are allowed!",
  CHARACTER_LEFT: "character Left.",
  GEN_REQUIRED: 'Genric password is required!',
  CERTIFICATE_NAMERE: 'Certificate Name is required!',
  GUARDIANISREQUIRED: 'Parent or Guardian\'s is required!',
  CLIENTSTATUS_REQUIRED: 'Client Status is required!',
  SUPPORT_REQUIRED: 'Support is required!',
  COMPANYSTATUS_REQUIRED: 'Company Status is required!',
  Event_Webinar_status: 'Event & Webinar Status is required!',
  CLIENTTYPE_REQUIRED: 'Client Type is required!',
  COMPANYTYPE_REQUIRED: 'Company Type is required!',
  autoComplete:'off',
}

