<div *ngIf="horizontalMenu" class="navbar-header d-xl-block">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex flex-wrap content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    
    <!--/ Bookmark -->
    <div *ngIf="!showNavbar">
      <ul class="nav navbar-nav mobile-menu d-block d-lg-none">
        <li class="nav-item">
          <a class="fileter-border" (click)="toggleSearchbar()">
            <span class="feather icon-search"></span>
          </a>
        </li>
        <li class="nav-item">
          <a id="fileter-b" class="fileter-border {{showFilter || selctedUserId ? 'active' : ''}} {{showFilter ? 'filter-arrow' : ''}}"
            (click)="openAdvancedFilter();preventCloseGrade()">
            <i class="fal fa-filter"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="">
            <svg xmlns="http://www.w3.org/2000/svg" width="32.746" viewBox="0 0 37.746 32.768">
              <g id="Group_265" data-name="Group 265" transform="translate(-795 -35)">
                <g id="file-text" transform="translate(792 34)">
                  <path id="Path_19" data-name="Path 19" d="M19,2H7A3,3,0,0,0,4,5V29a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V11Z"
                    fill="none" stroke="#6e6b7b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_20" data-name="Path 20" d="M14,2v9h9" transform="translate(5)" fill="none"
                    stroke="#6e6b7b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <line id="Line_15" data-name="Line 15" x1="12" transform="translate(10 19)" fill="none"
                    stroke="#0295c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <line id="Line_16" data-name="Line 16" x1="12" transform="translate(10 25)" fill="none"
                    stroke="#0295c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_21" data-name="Path 21" d="M11,9H8" transform="translate(2 3.5)" fill="none"
                    stroke="#0295c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                </g>
                <g id="Group_264" data-name="Group 264" transform="translate(4 1)">
                  <path id="heart"
                    d="M14,4.039a3.55,3.55,0,0,0-5.022,0l4.338,1.1L8.979,5.9l4.338.754-2.089,3.489L12.3,7.4H10.034v2.735L8.295,12.967V8.211L6.928,11.588V8.519L5.84,10.807,4.584,9.745,5.84,8.336,3.273,7.905l3.655-.873H5.505L4.138,6.454H2.589L3.78,5.141,5.2,4.723l3.1,1.731.429.578L8.295,4.723l-.684-.684A3.662,3.662,0,0,0,3.78,3.252a3.184,3.184,0,0,0-1.191.787,3.551,3.551,0,0,0,0,5.022l.684.684,5.022,5.022,5.022-5.022L14,9.06a3.55,3.55,0,0,0,0-5.022Z"
                    transform="translate(811.705 50.002)" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="4" />
                  <path id="heart-2" data-name="heart"
                    d="M14,4.039a3.55,3.55,0,0,0-5.022,0l4.338,1.1L8.979,5.9l4.338.754-2.089,3.489L12.3,7.4H10.034v2.735L8.295,12.967V8.211L6.928,11.588V8.519L5.84,10.807,4.584,9.745,5.84,8.336,3.273,7.905l3.655-.873H5.505L4.138,6.454H2.589L3.78,5.141,5.2,4.723l3.1,1.731.429.578L8.295,4.723l-.684-.684A3.662,3.662,0,0,0,3.78,3.252a3.184,3.184,0,0,0-1.191.787,3.551,3.551,0,0,0,0,5.022l.684.684,5.022,5.022,5.022-5.022L14,9.06a3.55,3.55,0,0,0,0-5.022Z"
                    transform="translate(811.705 50.002)" fill="none" stroke="#ea5455" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                </g>
              </g>
            </svg>
          </a>
        </li>
        <li class="nav-item"> <div ngbTooltip="Reset All Filters">
          <a class="fileter-border" (click)="resetAllFilter();resetAdvanceFilter();">
            <i class="fas fa-sync-alt"></i>
          </a>
        </div></li>

      </ul>
      <ul class="nav navbar-nav d-lg-flex align-items-center filter-button-row">
        <li class="nav-item d-none d-lg-block" id="searchbar">
          <form [formGroup]="searchFilterForm">
            <fieldset>
              <legend class="sr-only"></legend> <!-- Added legend -->
              <div class="input-group sreach-header-btn p-0">
                <div id="searchinput" class="form-control b-radius-left-side pr-30" (click)="hidetext('searchInput4')">
                  <input #searchInput4 type="text" formControlName="company_name"
                    (keyup)="searchFilter($event.target.value)" placeholder="Company, Client, Teacher, User"
                    >
                  <i class="fa fa-times" *ngIf="searchFilterForm.value.company_name" (click)="clearInputField()"></i>
                  <span *ngIf="placeholderText != ''" class="place">{{placeholderText}}
                  </span>
                  <div class="search-icon-right">
                    <i data-feather="search"></i>
                  </div>
                  <div *ngIf="IsDisplayFilterd" class="adv-accordion search-adv-accordion">
                    <div
                      class="adv-acc-header {{selectedRoleId == 0 || selectedRoleId ==  '' || selectedRoleId == null? '' : selectedRoleId == item.role_id ? '' : 'displayNone' }}"
                      *ngFor="let item of dat;let i = index;">
                      <button class="accordion-btn {{openChildAccordion == i ? 'active' : ''}}"
                        (click)="toggleAccordian(i)">
                        <span *ngIf="item.role_id == 4">{{item.Totaluser == 1 ? item.parentName : "Companies"}}:
                          {{item.Totaluser}} </span>
                        <span *ngIf="item.role_id == 5">{{item.Totaluser == 1 ? item.parentName : item.parentName+"s"}}:
                          {{item.Totaluser}} </span>
                        <span *ngIf="item.role_id == 2">{{item.Totaluser == 1 ? item.parentName : item.parentName+"s"}}:
                          {{item.Totaluser}} </span>
                        <span *ngIf="item.role_id == 3">{{item.Totaluser == 1 ? item.parentName : item.parentName+"s"}}:
                          {{item.Totaluser}} </span>
                        <span *ngIf="item.role_id == 8">{{item.Totaluser == 1 ? item.parentName : item.parentName+"s"}}:
                          {{item.Totaluser}} </span>
                        <span *ngIf="item.role_id == 1">{{item.Totaluser == 1 ? item.parentName : item.parentName+"s"}}:
                          {{item.Totaluser}} </span>
                        </button>

                      <div class="panel adv-acc-body " *ngIf="openChildAccordion == i">
                        <ul class="adv-list">
                          <li *ngFor="let child of item.childProperties " (click)="childClick(child,item.parentName)">
                            {{child.display_name}} </li>
                        </ul>
                      </div>
                    </div>

                    <div class="adv-no-record" *ngIf="dat?.length == 0 && isLoadingFilter == 2">
                      No Record Found
                    </div>
                  </div>
                </div>
                <div ngbDropdown class="input-group-append">
                  <button type="button" class="btn btn-outline-primary border-grey" ngbDropdownToggle
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" rippleEffect>
                    {{selectedRoleName?selectedRoleName:'Select'}}
                    <i class="fa fa-times" *ngIf="selectedRoleName !='Select'" (click)="onSelectRole('Select',0)"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-right mt-0">
                    <a ngbDropdownItem (click)="onSelectRole('Company',4)">Company</a>
                    <a ngbDropdownItem (click)="onSelectRole('Company Admin',5)">Company Admin</a>
                    <a ngbDropdownItem (click)="onSelectRole('Client',2)">Client</a>
                    <a ngbDropdownItem (click)="onSelectRole('Client Admin',3)">Client Admin</a>
                    <a ngbDropdownItem (click)="onSelectRole('Teacher',8)">Teacher</a>
                    <a *ngIf="!_show_client_report" ngbDropdownItem (click)="onSelectRole('User',1)">User</a>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </li>
        <li class="nav-item d-none d-lg-block" [ngbTooltip]="(!showFilter && selectIndustryTooltip && !selectCompanyTooltip && !selectClientTooltip) ? selectIndustryTooltip :
        (!showFilter && selectCompanyTooltip && !selectIndustryTooltip && !selectClientTooltip ) ? selectCompanyTooltip :
        (!showFilter && selectClientTooltip && !selectCompanyTooltip && !selectIndustryTooltip) ? selectClientTooltip :
        (!showFilter && selectCompanyTooltip && selectIndustryTooltip && !selectClientTooltip) ? selectCompanyTooltip :
        (!showFilter && selectIndustryTooltip && selectClientTooltip && !selectCompanyTooltip) ? selectClientTooltip :
        (!showFilter && selectCompanyTooltip && selectIndustryTooltip && selectClientTooltip) ? selectClientTooltip :
        null">
          <a class="fileter-border {{showFilter  ? 'active' : ''}} {{showFilter ? 'filter-arrow' : ''}} "
            (click)="openAdvancedFilter();preventCloseGrade()" ngbTooltip="Advance Filter">
            <i class="fal fa-filter"></i>
          </a>
        </li>
        <li class="nav-item d-none d-lg-block client-repoart-nav" (click)="sendId()"
          [ngClass]="{ 'active' : _show_client_report == true }">
          <a class="ml-1" ngbTooltip="Client Health Report">
            <div class="no-active-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="37.746" height="32.769" viewBox="0 0 37.746 32.769">
                <g id="Group_265" data-name="Group 265" transform="translate(-795 -35)">
                  <g id="file-text" transform="translate(792 34)">
                    <path id="Path_19" data-name="Path 19"
                      d="M19,2H7A3,3,0,0,0,4,5V29a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V11Z" fill="none" stroke="#6e6b7b"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_20" data-name="Path 20" d="M14,2v9h9" transform="translate(5)" fill="none"
                      stroke="#6e6b7b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line id="Line_15" data-name="Line 15" x1="12" transform="translate(10 19)" fill="none"
                      stroke="#6e6b7b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Line_16" data-name="Line 16" d="M12,0H0" transform="translate(10 25)" fill="#6e6b7b"
                      stroke="#6e6b7b" stroke-linecap="round" stroke-width="2" />
                    <path id="Path_21" data-name="Path 21" d="M11,9H8" transform="translate(2 3.5)" fill="none"
                      stroke="#6e6b7b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  </g>
                  <g id="Group_264" data-name="Group 264" transform="translate(4 1)">
                    <path id="heart"
                      d="M14,4.039a3.55,3.55,0,0,0-5.022,0l4.338,1.1L8.979,5.9l4.338.754-2.089,3.489L12.3,7.4H10.034v2.735L8.3,12.967V8.211L6.928,11.588V8.519L5.84,10.807,4.584,9.745,5.84,8.336,3.273,7.905l3.655-.873H5.505L4.138,6.454H2.589L3.78,5.141,5.2,4.723,8.3,6.454l.429.578L8.3,4.723l-.684-.684A3.662,3.662,0,0,0,3.78,3.252a3.184,3.184,0,0,0-1.191.787,3.551,3.551,0,0,0,0,5.022l.684.684L8.3,14.767l5.022-5.022L14,9.06a3.55,3.55,0,0,0,0-5.022Z"
                      transform="translate(811.705 50.002)" fill="none" stroke="#fff" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="4" />
                    <path id="heart-2" data-name="heart"
                      d="M14,4.039a3.55,3.55,0,0,0-5.022,0l4.338,1.1L8.979,5.9l4.338.754-2.089,3.489L12.3,7.4H10.034v2.735L8.3,12.967V8.211L6.928,11.588V8.519L5.84,10.807,4.584,9.745,5.84,8.336,3.273,7.905l3.655-.873H5.505L4.138,6.454H2.589L3.78,5.141,5.2,4.723,8.3,6.454l.429.578L8.3,4.723l-.684-.684A3.662,3.662,0,0,0,3.78,3.252a3.184,3.184,0,0,0-1.191.787,3.551,3.551,0,0,0,0,5.022l.684.684L8.3,14.767l5.022-5.022L14,9.06a3.55,3.55,0,0,0,0-5.022Z"
                      transform="translate(811.705 50.002)" fill="none" stroke="#ea5455" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" />
                  </g>
                </g>
              </svg>
            </div>
            <div class="active-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="37.746" height="32.769" viewBox="0 0 37.746 32.769">
                <g id="Group_265" data-name="Group 265" transform="translate(-795 -35)">
                  <g id="file-text" transform="translate(792 34)">
                    <path id="Path_19" data-name="Path 19"
                      d="M19,2H7A3,3,0,0,0,4,5V29a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V11Z" fill="none" stroke="#0295c9"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_20" data-name="Path 20" d="M14,2v9h9" transform="translate(5)" fill="none"
                      stroke="#0295c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line id="Line_15" data-name="Line 15" x1="12" transform="translate(10 19)" fill="none"
                      stroke="#0295c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line id="Line_16" data-name="Line 16" x1="12" transform="translate(10 25)" fill="none"
                      stroke="#0295c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_21" data-name="Path 21" d="M11,9H8" transform="translate(2 3.5)" fill="none"
                      stroke="#0295c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  </g>
                  <g id="Group_264" data-name="Group 264" transform="translate(4 1)">
                    <path id="heart"
                      d="M14,4.039a3.55,3.55,0,0,0-5.022,0l4.338,1.1L8.979,5.9l4.338.754-2.089,3.489L12.3,7.4H10.034v2.735L8.3,12.967V8.211L6.928,11.588V8.519L5.84,10.807,4.584,9.745,5.84,8.336,3.273,7.905l3.655-.873H5.505L4.138,6.454H2.589L3.78,5.141,5.2,4.723,8.3,6.454l.429.578L8.3,4.723l-.684-.684A3.662,3.662,0,0,0,3.78,3.252a3.184,3.184,0,0,0-1.191.787,3.551,3.551,0,0,0,0,5.022l.684.684L8.3,14.767l5.022-5.022L14,9.06a3.55,3.55,0,0,0,0-5.022Z"
                      transform="translate(811.705 50.002)" fill="none" stroke="#fff" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="4" />
                    <path id="heart-2" data-name="heart"
                      d="M14,4.039a3.55,3.55,0,0,0-5.022,0l4.338,1.1L8.979,5.9l4.338.754-2.089,3.489L12.3,7.4H10.034v2.735L8.3,12.967V8.211L6.928,11.588V8.519L5.84,10.807,4.584,9.745,5.84,8.336,3.273,7.905l3.655-.873H5.505L4.138,6.454H2.589L3.78,5.141,5.2,4.723,8.3,6.454l.429.578L8.3,4.723l-.684-.684A3.662,3.662,0,0,0,3.78,3.252a3.184,3.184,0,0,0-1.191.787,3.551,3.551,0,0,0,0,5.022l.684.684L8.3,14.767l5.022-5.022L14,9.06a3.55,3.55,0,0,0,0-5.022Z"
                      transform="translate(811.705 50.002)" fill="none" stroke="#ea5455" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" />
                  </g>
                </g>
              </svg>

            </div>

          </a>
        </li>

        <li class="nav-item d-none d-lg-block">
          
            <div ngbTooltip="Reset All Filters" class="nav-item">
              <a class="fileter-border ml-1" (click)="resetAllFilter();resetAdvanceFilter();">
                <i class="fas fa-sync-alt"></i>
              </a>
            </div>
     
        </li>

      </ul>
        
    </div>

  </div>
  <ul class="nav navbar-nav align-items-center ml-auto header-navbar-right ">
   
    <li class="nev-item d-none d-sm-block header-calander" *ngIf="!showNavbar">
      <div class="item-seldob-group d-flex flex-wrap align-items-center">
        <div ngbDropdown class="input-group-append">
          <button type="button" class="btn btn-outline-primary border-grey" ngbDropdownToggle data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" rippleEffect>
            <span class="filter-select-data" [innerHTML]="selectedDateType ? selectedDateType : 'Registration'"></span>
            <i class="fa fa-times" *ngIf="selectedDateType !='Registration'" (click)="onSelectDateType('Registration',1)"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-right mt-0">
            <a ngbDropdownItem *ngFor="let item of dropdownItems; 
                        let i = index" [class.active]="selectedItemIndex === i"
              (click)="onSelectDateType(item.label, item.value, i)" [innerHTML]="item.label">
              <!-- {{ item.label }} -->
            </a>
          </div>
        </div>
        <button class="input-group" #reportrange>
          <div
            class="pr-2 {{chosenDate ? 'form-control uneditable-input' : 'form-control uneditable-input date-placeholder'}}"
            name="daterange" class="show-calendar">
      
            {{chosenDate ? chosenDate : 'Search Date Range'}}
          </div>
          <div class="input-group-append">
            <div type="button" class="input-group-text"><i class="fal fa-calendar"></i></div>
          </div>
        </button>
      </div>
    </li>

    <!-- Calender -->
       <!-- Filterforlastloginandregistration -->
<!-- <li class="nev-item d-none d-sm-block advanceFilterReg" *ngIf="!showNavbar">
        <div ngbDropdown class="input-group-append">
          <button type="button" class="btn btn-outline-primary border-grey" ngbDropdownToggle data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" rippleEffect>
            <span class="filter-select-data"> {{selectedDateType?selectedDateType:'Advance Filter'}} </span>
            <i class="fa fa-times" *ngIf="selectedDateType !='Advance Filter'"
              (click)="onSelectDate('Advance Filter',0)"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-right mt-0">
            <a ngbDropdownItem *ngFor="let item of dropdownItems; 
              let i = index" [class.active]="selectedItemIndex === i"
              (click)="onSelectDate(item.label, item.value, i)">
              {{ item.label }}
            </a>
          </div>
        </div>
</li> -->



    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container *ngIf="data">
          <div class="user-nav d-md-flex d-none" [ngbTooltip]="(_authenticationService.getLogedInData())?.first_name + ' ' +(_authenticationService.getLogedInData())?.last_name">
            <span class="user-name font-weight-bolder">
              <!-- {{ data.first_name }} {{ data.last_name }} -->
              <!-- {{(_authenticationService.getLogedInData())?.first_name}} -->
              <!-- {{(_authenticationService.getLogedInData())?.last_name}} -->

            </span>
          </div>

          <span class="avatar" [ngbTooltip]="(_authenticationService.getLogedInData())?.first_name + ' ' +(_authenticationService.getLogedInData())?.last_name"><img class="round" #imgLogo (error)="imgLogo.src = defaultLogo"
              src="{{assetUrl}}{{(_authenticationService.getLogedInData())?.defaultProfilePicture}}" alt="avatar"
              height="40" width="40" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/admin/profile-setting']"><span [data-feather]="'user'"
            [class]="'mr-50'"></span> Profile</a>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
  <ul class="nav navbar-nav w-100 mt-1 d-block d-sm-none px-1">
    <li class="nev-item " *ngIf="!showNavbar">

<!-- version change -->
      <button class="input-group" (selected)="selectedDate($event)" (click)="onCllick()"
        [options]="datepicker">
        <div [class]="chosenDate ? 'form-control uneditable-input' : 'form-control uneditable-input date-placeholder'"
          name="daterange" class="show-calendar">
          {{chosenDate ? chosenDate : 'Search Date Range'}}
        </div>

        <div class="input-group-append">
          <span type="button" class="input-group-text"><i class="fal fa-calendar"></i></span>
        </div>
      </button>
      <div ngbDropdown class="input-group-append mob-slt-filter">
        <button type="button" class="btn btn-outline-primary border-grey" ngbDropdownToggle data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" rippleEffect>
          <span class="filter-select-data" [innerHTML]="selectedDateType ? selectedDateType : 'Registration'"></span>        
          <i class="fa fa-times" *ngIf="selectedDateType !='Registration'"
            (click)="onSelectDateType('Registration',1)"></i>
        </button>
        <div ngbDropdownMenu class="dropdown-menu-right mt-0">
          <a ngbDropdownItem *ngFor="let item of dropdownItems; 
                                let i = index" [class.active]="selectedItemIndex === i"
            (click)="onSelectDateType(item.label, item.value, i)" [innerHTML]="item.label">
            <!-- {{ item.label }} -->
            
          </a>
        </div>
      </div>
    </li>
  </ul>
</div>
<div (click)="preventCloseGrade()" class="w-100 advanc-nav-filter pb-0 {{showFilter ? 'show-advac--filter' : ''}}">

  <form class="navbar-container pb-0 pt-0">
    <div class="cstm-nav-wrapper">
      <div class="row health-repaort-nav-filter filer-with">

        <div class="filter-nav-col col">
          <div class="form-group custom-filter-teacher-hl">
            <ng-select (change)="changeFilter($event,'Industy_type')" class="custom-filter industry-type-tooltip-client'"
              placeholder="All Categories" [items]="industry_types" [multiple]="true" bindLabel="name" groupBy="client_name"
              [selectableGroup]="true"  [selectableGroupAsModel]="false" 
              [closeOnSelect]="false" bindValue="id" [searchable]="false" [formControl]="industry_type"
             tooltipClass="advanc-filter-tooltip" placement="bottom"
              container="body" (click)="focusInput('searchInput10')" #select10>
              <ng-template ng-header-tmp>
                <div class="form-group has-feedback">
                  <input #searchInput10 style="width: 100%;line-height: 24px" type="text" class="form-control"
                    placeholder="Search"
                    (input)="select10.filter($event.target.value)" />
                  <span class="form-control-feedback"></span>
                </div>
              </ng-template>
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <div [ngbTooltip]="item.client_name" placement="auto" container="body" class="module-name">{{item.client_name}}</div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div [ngbTooltip]="item.name" placement="auto" container="body">
                  {{item.name}}</div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div *ngIf="items.length == 1">
                  <span class="" style="padding: 2px;">
                    {{items[0].name}}
                  </span>
                </div>
                <div *ngIf="items.length > 1">
                  <span class="" style="padding: 2px;">
                    {{items.length}} selected
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>


        <div class="filter-nav-col col">
          <div class="form-group">
            <ng-select (change)="changeFilter($event,'Industry')" class="custom-filter industry-tooltip-client"
              placeholder="All Industries" [items]="industryDataBuffer$ | async" [multiple]="true" bindLabel="name" groupBy="selectAll"
              [selectableGroup]="true" [typeahead]="industryInput$" (clear)="clearIndustry()" [selectableGroupAsModel]="false"
              [closeOnSelect]="false" bindValue="id" [searchable]="false" [formControl]="industry_filter"
               tooltipClass="advanc-filter-tooltip" placement="bottom"
              container="body" (click)="focusInput('searchInput1')" #select1>
              <ng-template ng-header-tmp>
                <div class="form-group has-feedback">
                  <input #searchInput1 style="width: 100%;line-height: 24px" type="text" class="form-control"
                    placeholder="Search"
                    (input)="select1.filter($event.target.value);onSearchIndustry($event.target.value)" />
                  <span class="form-control-feedback"></span>
                </div>
              </ng-template>
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div [ngbTooltip]="item.name" placement="auto" container="body">
                  {{item.name}}</div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div *ngIf="items.length == 1">
                  <span class="" style="padding: 2px;">
                    {{items[0].name}}
                  </span>
                </div>
                <div *ngIf="items.length > 1">
                  <span class="" style="padding: 2px;">
                    {{items.length}} selected
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="filter-nav-col col">
          <div class="form-group">
            <ng-select (change)="changeFilter($event,'Company',select)" class="custom-filter company-tooltip-client"
              placeholder="All Companies / School Boards" [searchable]="false" [virtualScroll]="true"
              [items]="companyDatasBuffer$ | async" [loading]="companyLoading" [typeahead]="companyInput$"
              [multiple]="true" bindLabel="display_name" groupBy="selectAll" [selectableGroup]="true"
              (clear)="clearCompany()" [selectableGroupAsModel]="false" [closeOnSelect]="false" bindValue="id"
              [formControl]="company_filter"  tooltipClass="advanc-filter-tooltip"
              placement="bottom" container="body" (click)="focusInput('searchInput2')" #select2>

              <ng-template ng-header-tmp>
                <div class="form-group has-feedback">
                  <input #searchInput2 style="width: 100%;line-height: 24px" type="text" class="form-control"
                    placeholder="Search"
                    (input)=" select2.filter($event.target.value);onSearchCompany($event.target.value)" />


                  <span class="form-control-feedback"></span>
                </div>
              </ng-template>
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div [ngbTooltip]="item.display_name" placement="auto" container="body">
                  {{item.display_name}}</div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div *ngIf="items.length == 1">
                  <span class="" style="padding: 2px;">
                    {{items[0].display_name}}
                  </span>
                </div>
                <div *ngIf="items.length > 1">
                  <span class="" style="padding: 2px;">
                    {{items.length}} selected
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="filter-nav-col col {{clientDataBuffer.length > 100 ? 'disabled_outGroup': ''}}">
          <div class="form-group">
            <ng-select [virtualScroll]="true" [items]="clientDataBuffer$ | async" [loading]="clientLoading"
              [typeahead]="clientInput$" (change)="changeFilter($event,'Client')" class="custom-filter client-tooltip"
              placeholder="All Clients / Schools" [multiple]="true" bindLabel="display_name" groupBy="selectAll"
              [selectableGroup]="true" (clear)="clearclients()" [selectableGroupAsModel]="false" [closeOnSelect]="false"
              bindValue="id" [formControl]="clients_filter" [searchable]="false" 
              tooltipClass="advanc-filter-tooltip" placement="bottom" container="body" #select3
              (click)="focusInput('searchInput3')">
              <ng-template ng-header-tmp>
                <div class="form-group has-feedback">
                  <input #searchInput3 style="width: 100%;line-height: 24px" type="text" class="form-control test"
                    placeholder="Search"
                    (input)=" select3.filter($event.target.value);onSearchClient($event.target.value)" />
                  <span class="form-control-feedback"></span>
                </div>
                </ng-template>
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="client-select-container" (click)="updateSelectAll()" placement="auto" container="body">
                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" />
                    <div>
                      <span class="selectAll-text">Select All </span> <span *ngIf="clientDataBuffer.length > 100" class="tooltio-enb"
                        [ngbTooltip]="clientDataBuffer.length > 100 ? 'Cannot select more than 100 clients' : ''" placement="auto"
                        container="body"><i class="fas fa-info-circle"></i></span>
                    </div>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div [ngbTooltip]="item.display_name" placement="auto" container="body">
                    {{item.display_name}}</div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div *ngIf="items.length == 1">
                  <span class="" style="padding: 2px;">
                    {{items[0].display_name}}
                  </span>
                </div>
                <div *ngIf="items.length > 1">
                  <span class="" style="padding: 2px;">
                    {{items.length}} selected
                  </span>
                </div>
              </ng-template>

            </ng-select>

          </div>
        </div>

        <div class="filter-nav-col col">
          <div class="form-group custom-filter-teacher-hl">
            <ng-select [virtualScroll]="true" [items]="teacherDataBuffer" 
              [typeahead]="teacherInput$" (change)="changeFilter($event,'Teacher')" class="custom-filter teacher-tooltip-client"
              placeholder="All Teachers / Instructors" [multiple]="true" bindLabel="display_name" groupBy="client_names"
              [selectableGroup]="true"  [selectableGroupAsModel]="false" [closeOnSelect]="false"
              bindValue="id" [formControl]="teacher_filter" [searchable]="false"  
              tooltipClass="advanc-filter-tooltip" placement="bottom" container="body" #select11
              (click)="focusInput('searchInput11')" [searchFn]="customFilter1">
              <ng-template ng-header-tmp>
                <div class="form-group has-feedback">
                  <input #searchInput11 style="width: 100%;line-height: 24px" type="text" class="form-control test"
                    placeholder="Search"
                    (input)=" select11.filter($event.target.value)" />
                  <span class="form-control-feedback"></span>
                </div>
              </ng-template>
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <div [ngbTooltip]="item.client_names" placement="auto" container="body" class="module-name">{{item.client_names}}</div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div [ngbTooltip]="item.display_name" placement="auto" container="body">
                  {{item.display_name}}</div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div *ngIf="items.length == 1">
                  <span class="" style="padding: 2px;">
                    {{items[0].display_name}}
                  </span>
                </div>
                <div *ngIf="items.length > 1">
                  <span class="" style="padding: 2px;">
                    {{items.length}} selected
                  </span>
                </div>
              </ng-template>

            </ng-select>

          </div>
        </div>
      </div>
      <div class="cstm-right-btn--box">
        <div class="form-group">
          <div class="cstm-btn-nav--close">
            <button type="button" #closebutton class="close" (click)="openAdvancedFilter()">&times;</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>


<ng-template #modalInfo let-modal class="user-profile-modal">

  
  <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="modal-header">
          <div class="ea-container ea--both">
              <div class="pt-1 pb-1">
                  <h2 class="modal-title" id="myModalLabel160">Profile</h2>
              </div>
          </div>
          <button type="button" class="close" (click)="modal.dismiss('Cross click');clearInputField();" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="pt-1">
          <app-user-profile></app-user-profile>
      </div>
  </div>

</ng-template>