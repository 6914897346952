import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserProfileManagementRoutingModule } from './user-profile-management-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
// import { TabsModule } from 'ngx-bootstrap/tabs';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { SwiperModule } from 'swiper/angular';
import { ChartModule } from 'angular-highcharts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    // SwiperModule,  
    ChartModule,
    NgbModule,
    UserProfileManagementRoutingModule
  ],
  exports:[UserProfileComponent]
})
export class UserProfileManagementModule { }
